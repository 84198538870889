import { useQuery } from "@apollo/client";
import { useEffect } from "react"; 
import CHECK_COOKIES_QUERY from "../queries/check-cookies";
import { useLogoutMutation } from "./useLogoutMutation";
import { isEmpty } from "lodash";

export const useAuth = () => {
    const { logoutMutation } = useLogoutMutation();

    const { loading, error, data } = useQuery(CHECK_COOKIES_QUERY, {
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            let userData = localStorage.getItem("classic-user");
            if (!isEmpty(userData) && typeof window !== "undefined" && data && data?.checkCookies?.status == false) {
                logoutMutation();
                location.reload();
            }
        }
    });

    /*useEffect(() => {
        let userData = localStorage.getItem("classic-user");
        if (isEmpty(userData) && typeof window !== "undefined") {
            logoutMutation();
        }

    }, []);   */

    if(loading) return null;

    return { results: data?.checkCookies?.status };
};