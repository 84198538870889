
const MiddlePublicity = ({ options }) => {


    return (
        options.activePubMiddle == "checked" ? (
            <div
                className="middle-container flex flex-col justify-center items-center py-12 px-4 lg:px-0"
                style={{ backgroundColor: options?.pubMiddleBackgroundColor }}
            >
                <div
                    className="flex justify-center items-center text-center font-helvetica-text font-normal"
                    style={{
                        backgroundColor: options?.pubMiddleBackgroundColor,
                        color: options?.pubMiddleColor,
                        fontSize:
                            options && options?.pubTextMiddlePx
                                ? options?.pubTextMiddlePx + "px"
                                : "24px",
                    }}
                >
                    {options?.pubTextMiddle ? options?.pubTextMiddle : ""}
                </div>
                <div className="flex justify-center items-center text-center mt-4">
                    {options?.pubButtonMiddle1 ? (
                        <div
                            className="min-w-min px-2 text-sm underline tracking-wider cursor-pointer"
                            onClick={() => {
                                window.location = options?.pubButtonMiddle1;
                            }}
                            style={{ color: options?.pubMiddleColor }}
                        >
                            <span>
                                {options && options?.pubButton1TextMiddle
                                    ? options?.pubButton1TextMiddle
                                    : ""}
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
                    {options?.pubButtonMiddle2 ? (
                        <div
                            className={`${options?.pubButtonMiddle1 && options?.pubButtonMiddle2 ? "pl-6" : "px-2"} min-w-min text-sm underline tracking-wider cursor-pointer`}
                            onClick={() => {
                                window.location = options?.pubButtonMiddle2;
                            }}
                            style={{ color: options?.pubMiddleColor }}
                        >
                            <span>
                                {options && options?.pubButton2TextMiddle
                                    ? options?.pubButton2TextMiddle
                                    : ""}
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
                </div>


            </div>
        ) : (
            <></>
        )
    )
}

export default MiddlePublicity
