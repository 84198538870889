/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { BuilderComponent, builder } from "@builder.io/react";
import Layout from "../../Layout";
import { useAuth } from "../../../hooks/useAuth";
import '../../../components/builder-registry'; //importar en toda pagina que quiera usar los custom components

builder.init(`${process.env.NEXT_PUBLIC_BUILDER_API_KEY}`);

export default function HomeBuilder(props) {
	useAuth();

	return (
		<Layout
			urlPage=""
			stickyMenu={true}
			setting={props?.setting}
			seo={props?.seo}
			categories={props?.categories}
			footerMenu={props?.footerMenu}
			menu={props?.menu}
		>
			<h1 className="absolute -top-10">Tienda Online Classic Jeans Shop</h1>
			<BuilderComponent model="homepage" content={props.homepage} />
		</Layout>
	);
}