import Image from "next/image";
import { getImageFromCloudinary } from "../../../functions";

const MainHero = ({ options, isMobile }) => {

    return (
        options.activePubMain == "checked" ? (
            <div className="banner flex flex-col sm:flex-row justify-between overflow-hidden relative w-full h-full">
                <div className="banner-img flex items-center justify-between flex-wrap mx-auto content-center font-helvetica-text font-normal inset-0">
                    <div className="relative">
                        {isMobile ?
                            <Image
                                src={getImageFromCloudinary(options.mainImageMobile, ":best", 1080, 1920)}
                                alt="Classic Jeans promo principal"
                                width={1080}
                                height={1920}
                                placeholder="blur"
                                blurDataURL="data:image/jpeg;base64,/9j/UIRC#uoz?ws:VYj[o}fkRjkCjYayozayf5of"
                                quality={95}
                                priority={true}
                                unoptimized={true}
                            />
                            : <Image
                                src={getImageFromCloudinary(options.mainImageDesktop, ":best", 1920, 1080)}
                                alt="Classic Jeans promo principal"
                                width={1920}
                                height={1080}
                                placeholder="blur"
                                blurDataURL="data:image/jpeg;base64,/9j/UIRC#uoz?ws:VYj[o}fkRjkCjYayozayf5of"
                                quality={95}
                                priority={true}
                                unoptimized={true}
                            />
                        }
                    </div>
                    <div
                        className={`wp-block-cover__inner-container ${options && options?.positionSelect || options?.positionSelectMobile
                            ? isMobile ? options?.positionSelectMobile : options?.positionSelect
                            : ""
                            }`}
                    >
                        <div
                            className={`${(options && options?.positionSelect == "cj-center-top") ||
                                options?.positionSelect == "cj-center-absolute" ||
                                options?.positionSelect == "cj-center-bottom" ||
                                options?.positionSelectMobile == "cj-center-top" ||
                                options?.positionSelectMobile == "cj-center-absolute" ||
                                options?.positionSelectMobile == "cj-center-bottom"
                                ? "flex flex-col justify-center items-center text-center"
                                : options?.positionSelectMobile == "cj-center-bottom-outline" ? "flex flex-col justify-center text-center items-center mt-10 px-8" : "justify-start text-left"
                                }`}
                        >
                            {options && options?.mainText11 ? (
                                isMobile ? (
                                    <p
                                        className="has-large-font-size antialiased"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText11mPx
                                                    ? options?.mainText11mPx + "px"
                                                    : "50px",
                                        }}
                                    >
                                        {options?.mainText11}
                                    </p>
                                ) : (
                                    <p
                                        className="has-large-font-size antialiased"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText11Px
                                                    ? options?.mainText11Px + "px"
                                                    : "90px",
                                        }}
                                    >
                                        {options?.mainText11}
                                    </p>
                                )
                            ) : (
                                ""
                            )}

                            {options && options?.mainText12 ? (
                                isMobile ? (
                                    <p
                                        className="has-extra-large-font-size antialiased pt-2"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText12mPx
                                                    ? options?.mainText12mPx + "px"
                                                    : "30px",
                                        }}
                                    >
                                        {options?.mainText12}
                                    </p>
                                ) : (
                                    <p
                                        className="has-extra-large-font-size antialiased pt-4"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText12Px
                                                    ? options?.mainText12Px + "px"
                                                    : "50px",
                                        }}
                                    >
                                        {options?.mainText12}
                                    </p>
                                )
                            ) : (
                                ""
                            )}
                            {options && options?.mainText13 ? (
                                isMobile ? (
                                    <p
                                        className="has-extra-large-font-size pt-2 antialiased"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText13mPx
                                                    ? options?.mainText13mPx + "px"
                                                    : "25px",
                                        }}
                                    >
                                        {options?.mainText13}
                                    </p>
                                ) : (
                                    <p
                                        className="has-extra-large-font-size pt-4 antialiased"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText13Px
                                                    ? options?.mainText13Px + "px"
                                                    : "40px",
                                        }}
                                    >
                                        {options?.mainText13}
                                    </p>
                                )
                            ) : (
                                ""
                            )}
                            {options && options?.mainText14 ? (
                                isMobile ? (
                                    <p
                                        className="has-large-font-size antialiased pt-2"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText14mPx
                                                    ? options?.mainText14mPx + "px"
                                                    : "25px",
                                        }}
                                    >
                                        {options?.mainText14}
                                    </p>
                                ) : (
                                    <p
                                        className="has-large-font-size antialiased pt-4"
                                        style={{
                                            color:
                                                options && options?.mainColor
                                                    ? options?.mainColor
                                                    : "#FFFFFF",
                                            fontSize:
                                                options && options?.mainText14Px
                                                    ? options?.mainText14Px + "px"
                                                    : "40px",
                                        }}
                                    >
                                        {options?.mainText14}
                                    </p>
                                )
                            ) : (
                                ""
                            )}
                            <>
                                <div className="flex gap-6 mt-8">
                                    {options?.pubButton1TextMain ? (
                                        <button
                                            className="w-35 py-3 rounded-full text-sm hover:bg-[#323232] antialiased"
                                            style={{
                                                color:
                                                    options && options?.btnColor
                                                        ? options?.btnColor
                                                        : "#FFFFFF",
                                                backgroundColor:
                                                    options && options?.btnBgColor
                                                        ? options?.btnBgColor
                                                        : "#000000",

                                            }}
                                            onClick={() => {
                                                window.location = options?.pubButton1Main;
                                            }}
                                        >
                                            <span>
                                                {options && options?.pubButton1TextMain
                                                    ? options?.pubButton1TextMain
                                                    : ""}
                                            </span>
                                            <style jsx>{`
                                                button:hover {
                                                    color: ${options && options?.btnBgColor ? options?.btnBgColor : "#000000"} !important;
                                                    background-color: ${options && options?.btnColor ? options?.btnColor : "#FFFFFF"} !important;
                                                    box-shadow: 0 0px 8px 2px ${options && options?.btnBgColor ? options?.btnBgColor : "#000000"} !important;
                                                }
                                            `}</style>
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    {options?.pubButton2TextMain ? (
                                        <button
                                            className="w-35 py-3 rounded-full text-sm hover:bg-[#323232] antialiased"
                                            style={{
                                                color:
                                                    options && options?.btnColor
                                                        ? options?.btnColor
                                                        : "#FFFFFF",
                                                backgroundColor:
                                                    options && options?.btnBgColor
                                                        ? options?.btnBgColor
                                                        : "#000000",
                                            }}
                                            onClick={() => {
                                                window.location = options?.pubButton2Main;
                                            }}
                                        >
                                            <span>
                                                {options && options?.pubButton2TextMain
                                                    ? options?.pubButton2TextMain
                                                    : ""}
                                            </span>
                                            <style jsx>{`
                                                button:hover {
                                                    color: ${options && options?.btnBgColor ? options?.btnBgColor : "#000000"} !important;
                                                    background-color: ${options && options?.btnColor ? options?.btnColor : "#FFFFFF"} !important;
                                                    box-shadow: 0 0px 8px 2px ${options && options?.btnBgColor ? options?.btnBgColor : "#000000"} !important;
                                                }
                                            `}</style>
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
    )
}

export default MainHero
