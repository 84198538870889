import gql from "graphql-tag";

/**
 * GraphQL check if cookies is not expired.
 */
const CHECK_COOKIES_QUERY = gql`query {
  checkCookies {
    status
  }
}
`;

export default CHECK_COOKIES_QUERY;
