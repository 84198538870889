/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Layout from "../../Layout";
import { useAuth } from "../../../hooks/useAuth";
import SwiperCarousel from "../product-carousel/swiper";
import { rowCreateByDate, getImageFromCloudinary } from "../../../functions";
import ProductQuickAdd from "../../product/ProductQuickAdd";
import MiddlePublicity from "../middle-publicity";
import MainHero from "../main-hero";

export default function HomeDefault(props) {
	useAuth();
	const { mujer, hombre, options, isMobile } = props;
	const [Women, setWomen] = useState([]);
	const [Men, setMen] = useState([]);

	useEffect(() => {
		if (typeof window !== "undefined") {
			let mujerToShow = rowCreateByDate(mujer, 9);
			let HombreToShow = rowCreateByDate(hombre, 9);

			setWomen(mujerToShow);
			setMen(HombreToShow);
		}
	}, [mujer, hombre]);

	return (
		<Layout
			urlPage=""
			stickyMenu={true}
			setting={props?.setting}
			seo={props?.seo}
			categories={props?.categories}
			footerMenu={props?.footerMenu}
			menu={props?.menu}
		>
			<h1 className="absolute -top-10">Tienda Online Classic Jeans Shop</h1>
			{options?.activePubMiddle == "checked" && <MiddlePublicity options={options}/>}
			{options?.activePubMain == "checked" && <MainHero options={options} isMobile={isMobile}/>}
			{/*Products for women*/}
			<div className="products mt-18 relative">
				{Women?.length ? (
					<SwiperCarousel title={"Lo Más Nuevo En Mujeres"} isLanding={true}>
						{Women.map((product) => (
							<ProductQuickAdd
								key={product.id}
								product={product}
								isMobile={isMobile}
								hideFilter={true}
							/>
						))}
					</SwiperCarousel>
				) : (
					""
				)}
			</div>

			{/*<div className="w-full h-96 bg-fixed bg-cover bg-center flex justify-center items-center parallax"></div>*/}

			{/*Products for men*/}
			<div className="products my-18 relative">
				{Men?.length ? (
					<SwiperCarousel title={"Lo Más Nuevo En Hombres"} isLanding={true}>
						{Men.map((product) => (
							<ProductQuickAdd
								key={product.id}
								product={product}
								isMobile={isMobile}
								hideFilter={true}
							/>
						))}
					</SwiperCarousel>
				) : (
					""
				)}
			</div>
		</Layout>
	);
}
